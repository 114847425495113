import React, { useState } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

import ServiceLog from '../services/serviceLog';

import animBtnLoading from '../assets/anim/anim-btnLoading.json';
import logo from '../assets/image/logo_sq.png';

export default function PageHome() {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submit, setSubmit] = useState(false);

    const logService = new ServiceLog();

    const formVSchema = Yup.object().shape({
        name: Yup.string().required('This information is required'),
        phone: Yup.string().required('Phone number is required').matches(/^[0-9]{10}$/, { message: 'Please enter a valid mobile number' }),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            message: '',
            isCheck: false,
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                "name": values.name,
                "email": values.email.length === 0 ? "NA" : values.email,
                "phone": values.phone.length === 0 ? "NA" : values.phone,
                "message": values.message.length === 0 ? "NA" : values.message,
            }
            logService.sendData(body, 'c3bd6eeb-3e12-4bc2-bd9b-c58eb6a3eaf7').then((res) => {
                setSubmit(false);
                if (res.status) {
                    setIsSubmitted(true);
                    toast.success("Inquiry submitted!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                } else {
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                handleReset();
            });
        }
    });

    const saveContact = () => {
        var contact = {
            name: "Gunjan Gala",
            phone: "+917770082228",
            email: "Interio@visionventura.com"
        };

        var vcard = "BEGIN:VCARD\nVERSION:4.0\nFN:" + contact.name + "\nTEL;TYPE=work,voice:" + contact.phone + "\nEMAIL:" + contact.email + "\nEND:VCARD";
        var blob = new Blob([vcard], { type: "text/vcard" });
        var url = URL.createObjectURL(blob);

        const newLink = document.createElement('a');
        newLink.download = contact.name + ".vcf";
        newLink.textContent = contact.name;
        newLink.href = url;

        newLink.click();
    }

    const gotoLink = (link) => {
        window.open(link, '_blank');
    }

    return (
        <>
            <div className='w-full h-screen bg-gray-50 relative'>
                <div className='flex flex-col items-center justify-center my-6 mx-6'>
                    <img src={logo} alt='Bodements Logo' className='w-[600px] mt-1 mx-2' />
                </div>
                <div>
                    {
                        !isSubmitted && <div className="relative w-[98%] max-w-2xl h-auto mx-auto bg-white border rounded-md mt-10 p-4">
                            <div className='flex items-center justify-between'>
                                <h3 className="text-xl font-semibold text-gray-900">
                                    Connect With US &nbsp;
                                </h3>
                            </div>
                            <hr className='mt-2' />
                            <form className='mt-4' onSubmit={handleSubmit} id="plan-form">
                                <div className="mb-4">
                                    <label className="block mb-2 text-sm font-medium text-gray-600">Full Name<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="name" name="name" value={values.name} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. full name" onChange={handleChange} />
                                    {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                </div>
                                <div className="mb-4">
                                    <label className="block mb-2 text-sm font-medium text-gray-600">Phone Number<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="phone" name="phone" value={values.phone} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. phone number" onChange={handleChange} />
                                    {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                </div>
                                <div className="mb-4">
                                    <label className="block mb-2 text-sm font-medium text-gray-600">Email Address<span className='text-gray-400 text-xs'> (optional)</span></label>
                                    <input type="text" id="email" name="email" value={values.email} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. email address" onChange={handleChange} />
                                    {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                </div>
                                <div className="mb-4">
                                    <label className="block mb-2 text-sm font-medium text-gray-600">Inquiry Message<span className='text-gray-400 text-xs'> (optional)</span></label>
                                    <textarea type="text" id="message" name="message" value={values.message} className="bg-gray-50 border h-40 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. inquiry message" onChange={handleChange} />
                                    {(errors.message && touched.message) && <p className='text-xs text-red-400 mt-1'>{errors.message}</p>}
                                </div>
                                <div className='flex items-end'>
                                    <button type="submit" className="text-white bg-primeLight hover:bg-prime focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mt-4" disabled={submit}>
                                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                        {!submit && "SUBMIT"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    }
                    {
                        isSubmitted && <div className='flex items-center justify-center h-[400px]'>
                            <div className='text-center'>
                                <h1 className='text-2xl'>Thank you for reaching out.</h1>
                                <p className='text-sm mt-2'>We will connect with you asap!</p>
                            </div>
                        </div>
                    }
                </div>
                <div className='my-[20px]'>
                    <div className='mt-4 w-full flex flex-col items-center'>
                        <div className='w-[90%] text-center text-primeLight font-medium border border-primeLight rounded p-2 mt-2 mb-2 flex items-center justify-center cursor-pointer' onClick={() => {
                            saveContact();
                        }}>
                            SAVE CONTACT
                        </div>
                        <div className='w-[90%] text-center text-primeLight font-medium border border-primeLight rounded p-2 mt-2 mb-2 flex items-center justify-center cursor-pointer' onClick={() => {
                            gotoLink("https://www.google.com/maps/place/JIVAN+SAFALYA+-+by+J.B.+Constructions/@19.2142908,73.0858439,17z/data=!3m1!4b1!4m6!3m5!1s0x3be795002a752aa9:0xa304ef2f3a1d1aa5!8m2!3d19.2142858!4d73.0884188!16s%2Fg%2F11vr2j26h9?authuser=0&entry=ttu");
                        }}>
                            VISIT US
                        </div>
                        <div className='w-[90%] text-center text-primeLight font-medium border border-primeLight rounded p-2 mt-2 mb-2 flex items-center justify-center cursor-pointer' onClick={() => {
                            gotoLink("upi://pay?pa=visionventura11.11@icici&pn=MSVISIONVENTURA&cu=INR");
                        }}>
                            PAYMENT DETAILS
                        </div>
                        <div className='w-[90%] text-center border rounded p-2 mt-2 mb-2 flex items-center justify-center cursor-pointer' onClick={() => {
                            gotoLink('https://visionventura.com/');
                        }}>
                            <i class="las la-external-link-alt text-xl mr-2"></i> Our website
                        </div>
                        <div className='w-[90%] text-center border rounded p-2 my-2 flex items-center justify-center cursor-pointer' onClick={() => {
                            gotoLink('https://www.instagram.com/visionventura/');
                        }}>
                            <i className="lab la-instagram text-xl mr-2"></i> Instagram
                        </div>
                        <div className='w-[90%] text-center border rounded p-2 my-2 flex items-center justify-center cursor-pointer' onClick={() => {
                            gotoLink('https://www.youtube.com/@interiorsbygunjangala/');
                        }}>
                            <i className="lab la-youtube text-xl mr-2"></i> YouTube
                        </div>
                        <div className='w-[90%] text-center border rounded p-2 my-2 flex items-center justify-center cursor-pointer' onClick={() => {
                            gotoLink('https://www.facebook.com/visionventura11.11/');
                        }}>
                            <i className="lab la-facebook text-xl mr-2"></i> Facebook
                        </div>
                    </div>
                </div>
                <div className='h-[48px] bg-prime w-full flex justify-center items-center'>
                    <p className='text-sm text-white text-center'>© 2024 Vision Ventura</p>
                </div>
            </div>
        </>
    )
}
