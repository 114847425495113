import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { modeAtom } from './core/config/atoms';

import PageHome from './pages/PageHome';
import Page404 from './pages/Page404';

export default function App() {

    const [modeStatus] = useRecoilState(modeAtom);

    useEffect(() => {
        const bodyEl = document.querySelector("body");
        modeStatus ? bodyEl.classList.add("dark") : bodyEl.classList.remove("dark");
    }, [modeStatus]);

    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/" element={<PageHome />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Router>
        </>
    )
}